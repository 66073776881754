<template>
  <v-container>
    <v-row>
      <v-col>
        <div>
          <h1 class="ml-2 mt-2" style="font-weight: 200">
            Activity {{ activityName }}
          </h1>
          <div class="d-flex">
            <v-btn
              x-small
              class="grey--text ml-2"
              text
              elevation="0"
              @click="
                () => this.$router.push({ path: '/activity-and-species' })
              "
              >All Activities</v-btn
            >
          </div>
          <div class="d-flex flex-column ml-4 mr-4 mt-4">
            <div v-if="!editMode">
              <v-text-field
                :value="singleActivity.id"
                label="ID"
                class="managrx-input mr-2"
                outlined
                dense
                readonly
                filled
              ></v-text-field>
              <v-text-field
                :value="singleActivity.name"
                label="Nombre"
                class="managrx-input mr-2"
                outlined
                dense
                readonly
                filled
              ></v-text-field>

              <v-select
                outlined
                dense
                filled
                readonly
                :value="selectedSpecies"
                :items="species"
                item-text="name"
                item-value="id"
                label="Species"
              >
              </v-select>
            </div>
            <div v-if="editMode">
              <v-text-field
                :value="activityCopy.id"
                label="ID"
                class="managrx-input mr-2"
                outlined
                dense
                filled
                readonly
              ></v-text-field>
              <v-text-field
                v-model="activityCopy.name"
                label="Nombre"
                class="managrx-input mr-2"
                outlined
                dense
              ></v-text-field>

              <v-select
                outlined
                dense
                v-model="activityCopy.speciesId"
                :items="species"
                item-text="name"
                item-value="id"
                label="Species"
              >
              </v-select>
            </div>

            <div class="d-flex">
              <v-btn
                color="warning"
                class="mr-2"
                @click="enterEditMode"
                v-if="!editMode"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>

              <v-btn
                color="error"
                class="mr-2"
                @click="confirmDelete"
                v-if="!editMode"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>

              <v-btn
                color="primary white--text"
                @click="saveClick"
                v-if="editMode"
                class="mr-2"
              >
                <v-icon>mdi-floppy</v-icon>
              </v-btn>
              <v-btn
                color="blue white--text"
                class="mr-2"
                @click="exitEditMode"
                v-if="editMode"
              >
                Atrás
              </v-btn>
            </div>
          </div>

          <!-- Add confirmation dialog -->
          <v-dialog v-model="deleteDialog" max-width="400">
            <v-card>
              <v-card-title class="headline"
                >Confirmar eliminación</v-card-title
              >
              <v-card-text>
                ¿Está seguro que desea eliminar la actividad "{{
                  singleActivity.name
                }}"?
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="grey darken-1" text @click="deleteDialog = false">
                  Cancelar
                </v-btn>
                <v-btn color="error" text @click="deleteActivity">
                  Eliminar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["singleActivity", "species"]),
    activityName() {
      return this.singleActivity?.name;
    },
    selectedSpecies() {
      console.log("Single Activity:", this.singleActivity);
      console.log("Available Species:", this.species);
      const found = this.species.find(
        (s) => s.id === this.singleActivity.speciesId
      );
      console.log("Found Species:", found);
      return found;
    },
  },

  mounted() {
    this.$store.dispatch("getSingleActivity", this.$route.params.id);
    this.$store.dispatch("getSpecies");
  },

  methods: {
    enterEditMode() {
      this.activityCopy = { ...this.singleActivity };
      this.editMode = true;
    },
    exitEditMode() {
      this.editMode = false;
    },
    async saveClick() {
      let updates = {
        id: this.activityCopy.id,
        name: this.activityCopy.name,
        speciesId: this.activityCopy.speciesId,
      };

      let response = await this.$store.dispatch("updateActivity", updates);

      if (response?.success) {
        this.editMode = false;
      }
    },
    confirmDelete() {
      this.deleteDialog = true;
    },
    async deleteActivity() {
      try {
        const response = await this.$store.dispatch(
          "deleteActivity",
          this.$route.params.id
        );
        if (response?.success) {
          this.$store.dispatch("setNewNotification", {
            data: {
              type: "success",
              text: `Activity "${this.singleActivity.name}" deleted successfully`,
            },
          });
          this.$router.push("/activity-and-species");
        }
      } finally {
        this.deleteDialog = false;
      }
    },
  },

  data() {
    return {
      editMode: false,
      activityCopy: {},
      deleteDialog: false,
    };
  },
};
</script> 